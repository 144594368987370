@font-face {
  font-family: 'Averia Libre';
  src: url('/fonts/AveriaLibre.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Averia Libre Bold';
  src: url('/fonts/AveriaLibreBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}